import React,{useState,useCallback, useEffect} from 'react';
import './Header.scss'
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useNavigate, Link } from 'react-router-dom';
const Header = (props) => {

    const [y, setY] = useState(window.scrollY);
    const [scrollMenu, setScroll] = useState('')
    const [logo, setLogo] = useState('/assets/Home/iconos-logos/img_logo_puntavela.svg')
    const navigate = useNavigate();
    const handleNavigation = useCallback(
      (e) => {
        const window = e.currentTarget;
        if (y > window.scrollY) {
            if(window.scrollY <=150){
                setScroll('')
                setLogo('/assets/Home/iconos-logos/img_logo_puntavela.svg')
           }
        } else if (y < window.scrollY) {
            if(window.scrollY >= 150){
                setScroll('back_menu')
                setLogo('/assets/Home/iconos-logos/svg-iso-puntavela.svg')
            }
        }
        setY(window.scrollY);
      },
      [y]
    );
    const scrollTarget = (target) => { 
        scroller.scrollTo(target, {smooth: true, duration: 700})
      };
      
      const scrollToPage =  (target) => {
        navigate(`/${target}`)
        scrollTarget(target.slice(1));
      };

    useEffect(() => {
      setY(window.scrollY);
      window.addEventListener("scroll", handleNavigation);
  
      return () => {
        window.removeEventListener("scroll", handleNavigation);
      };
    }, [handleNavigation]);

    const handleMenu = (type) =>{
        if(type) scrollToPage(type)
        props.setOpen(props.open === 'menu-open' ? '' : 'menu-open')
            
    }
    const dispoTemp = () =>{
        props.setOpen(props.open === 'menu-open' ? '' : 'menu-open')
        props.setImagen(true)
    }

    return ( 
        <>
            <header className={`header  ${scrollMenu}`}>
                <div className='icon_menu'>
                    <p className="btn-toggle" onClick={()=> handleMenu()} ><span></span></p> <p>MENÚ</p>
                </div>
                <div className='icon_logo'>
                    <a href="/"><img src={logo} alt="logo" /></a>
                </div>
                <div className='telefono'>
                    <a href="tel:+526566385985" target={'_blank'}>T. (656) 638 5985</a>
                </div>
                <nav className='menu'>
                    <ul>
                        <li><a href="#concepto" onClick={()=> handleMenu('#concepto')}>CONCEPTO</a></li>
                        <li><a href='#recorrido' onClick={()=> handleMenu('#masterplan')}>RECORRIDO 360</a></li>
                        <li><Link to="/galeria" onClick={()=> handleMenu()}>GALERÍA</Link></li>
                        <li><a href="/disponibilidad" onClick={()=> handleMenu()}>DISPONIBILIDAD</a></li>
                        <li><a href="#amenidades" onClick={()=> handleMenu()}>AMENIDADES</a></li>
                        <li><a href="#ubicacion" onClick={()=> handleMenu()}>UBICACIÓN</a></li>
                        <li><a href="https://grupovelas.com.mx/" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-logo-grupo-velas.svg" alt="Grupo velas" /></a></li>
                    </ul>
                </nav>
                <div className='social-media'>
                    <a href="https://api.whatsapp.com/send?phone=+526566385985&text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PuntaVela" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-icon-whatsapp.svg" alt="whatsapp" /></a>
                    <a href="https://www.facebook.com/puntavela" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-icon-facebook.svg" alt="facebook" /></a>
                    <a href="https://www.instagram.com/puntavelajuarez/" target={'_blank'}><img src="/assets/Home/iconos-logos/svg-icon-instagram.svg" alt="instagram" /></a>
                </div>
            </header>
            
        </>
     );
}
 
export default Header;