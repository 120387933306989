import React, { useRef, useState, useEffect } from "react";
import "./Prototypes.scss";
import {
  FloorType,
  PrototypeTypes,
  getPrototype,
} from "../../utils/prototypes";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Autoplay } from "swiper/core";
const Prototypes = () => {
  const Alpe = getPrototype(PrototypeTypes.Alpe);
  const Celta = getPrototype(PrototypeTypes.Celta);
  const Mirabel = getPrototype(PrototypeTypes.Mirabel);
  const [dataPrototype, setDataPrototype] = useState(Alpe);
  const Ref = useRef(null);
  const handleActive = (item) => {
    if (item === "ALPE") {
      Ref.current.style.left = "0%";
      Ref.current.style.width = "100px";
      if (window.innerWidth <= 550) {
        Ref.current.style.width = "70px";
      }
      setDataPrototype(Alpe);
    }
    if (item === "CELTA") {
      Ref.current.style.left = "32%";
      Ref.current.style.width = "110px";
      if (window.innerWidth <= 550) {
        Ref.current.style.left = "31%";
        Ref.current.style.width = "75px";
      }
      setDataPrototype(Celta);
    }
    if (item === "MIRABEL") {
      Ref.current.style.left = "67%";
      Ref.current.style.width = "150px";
      if (window.innerWidth <= 550) {
        Ref.current.style.left = "65%";
        Ref.current.style.width = "100px";
      }
      setDataPrototype(Mirabel);
    }
  };
  const listenResize = () => {
    if (window.innerWidth <= 550) {
        if (dataPrototype.name === "ALPE") {
            Ref.current.style.width = "70px";
          }
          if (dataPrototype.name === "CELTA") {
            Ref.current.style.left = "31%";
            Ref.current.style.width = "75px";
          }
          if (dataPrototype.name === "MIRABEL") {
            Ref.current.style.left = "65%";
            Ref.current.style.width = "100px";
          }
    }else{
        if (dataPrototype.name === "ALPE") {
            Ref.current.style.left = "0%";
            Ref.current.style.width = "100px";
            if (window.innerWidth <= 550) {
              Ref.current.style.width = "70px";
            }
            setDataPrototype(Alpe);
          }
          if (dataPrototype.name === "CELTA") {
            Ref.current.style.left = "32%";
            Ref.current.style.width = "110px";
            if (window.innerWidth <= 550) {
              Ref.current.style.left = "31%";
              Ref.current.style.width = "75px";
            }
            setDataPrototype(Celta);
          }
          if (dataPrototype.name === "MIRABEL") {
            Ref.current.style.left = "67%";
            Ref.current.style.width = "150px";
            if (window.innerWidth <= 550) {
              Ref.current.style.left = "65%";
              Ref.current.style.width = "100px";
            }
            setDataPrototype(Mirabel);
          }
    }
  };
  useEffect(() => {
    window.addEventListener('resize', listenResize);
  });
  useEffect(()=>{
    listenResize()
  },[])
  return (
    <div className="prototypes">
      <div className="tap-prototypes">
        <button onClick={() => handleActive(Alpe.name)}>{Alpe.name}</button>
        <button onClick={() => handleActive(Celta.name)}>{Celta.name}</button>
        <button onClick={() => handleActive(Mirabel.name)}>
          {Mirabel.name}
        </button>
        <div ref={Ref} className="linea"></div>
      </div>
      <div className="detail">
        <Details dataPrototype={dataPrototype} />
        <Carousel dataPrototype={dataPrototype} />
      </div>
    </div>
  );
};

const Details = ({ dataPrototype }) => {
  return (
    <div className="left-detail">
      <h1>{dataPrototype.name}</h1>
      <ul>
        <li>
          Terreno {dataPrototype.terreno} m<sup>2</sup>
        </li>
        <li>
          {dataPrototype.construccion} m<sup>2</sup> construidos
        </li>
      </ul>
      <div className="left-detail-row">
        <div className="left-detail-row-left">
          <h3>PLANTA BAJA</h3>
          <ul>
            {dataPrototype.floor[FloorType.LowFloor].features.map(
              (item, index) => (
                <li key={index}>{item.characteristics}</li>
              )
            )}
          </ul>
        </div>
        <div className="left-detail-row-right">
          <h3>PLANTA ALTA</h3>
          <ul>
            {dataPrototype.floor[FloorType.TopFloor].features.map(
              (item, index) => (
                <li key={index}>{item.characteristics}</li>
              )
            )}
          </ul>
        </div>
      </div>
      <a href="/disponibilidad">VER DISPONIBILIDAD</a>
    </div>
  );
};

const Carousel = ({ dataPrototype }) => {
    const RefSlide = useRef()
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="right-detail">
      {showModal && (
        <div className="modal-slider">
            <div className="slider">
                <button onClick={()=>setShowModal(false)}>X</button>
                <Swiper
                spaceBetween={10}
                centeredSlides={true}
                onInit={(swiper)=>{
                    RefSlide.current = swiper
                }}
                className="modal-slide"
                autoplay={{
                delay: 4000,
                }}
                modules={[FreeMode, Autoplay]}
            >
                {dataPrototype.gallery.map((image) => (
                <SwiperSlide>
                    <img src={image} alt="" />
                </SwiperSlide>
                ))}
            </Swiper>
            </div>
        </div>
      )}
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        onClick={(swiper) => {
            setShowModal(true)
            RefSlide.current.slideTo(swiper.realIndex,0.5)
        }}
        autoplay={{
          delay: 4000,
        }}
        modules={[FreeMode, Autoplay]}
      >
        {dataPrototype.gallery.map((image) => (
          <SwiperSlide>
            <img src={image} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default Prototypes;
