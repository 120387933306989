import React, { useEffect } from 'react';
import Principal from "../Principal/Principal";
import Conceptos from "../Conceptos/Conceptos";
import { animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { useNavigate, useLocation } from 'react-router-dom';
import Recorrido from '../Recorrido/Recorrido';
import Prototypes from '../Prototypes/Prototypes';
import Amenidades from '../Amenidades/Amenidades';
import './Home.scss'
const Home1 = ({setUrlVideo, setVideo}) => {
    const navigate = useNavigate();
    const actualUrl = useLocation();
    const slider = [
      "/assets/Home/img_sala_vela-towers.jpg",
      "/assets/Home/img-cocina-punta_vela.jpg",
      "/assets/Home/img_tipo-1_velatowers.jpg",
      "/assets/Home/img-recamara1-punta_vela.jpg",
      "/assets/Home/img-recamara2-punta_vela.jpg"
    ]
    const scrollTarget = (target) => { 
        scroller.scrollTo(target, {smooth: true, duration: 700})
      };
      
      const scrollToPage =  (target) => { 
        navigate(`/${target}`)
        scrollTarget(target.slice(1));
      };
      useEffect(()=>{
        scrollToPage(actualUrl.hash)
      },[]);
    return(
        <>
        <div>
            <Principal/>
            <Conceptos id={"concepto"} title={["DESCUBRE","PUNTA VELA"]} description={"Punta Vela es un un clúster de únicamente 63 casas con acceso controlado y amenidades para toda la familia, que generan un ambiente SEGURO y EXCLUSIVO."} type={"video"} urlItem={"a3IBxHwmXlU"} setVideo={setVideo} setUrlVideo={setUrlVideo} buttonLink={"/disponibilidad"} buttonText={"VER DISPONIBILIDAD"}/>
            <Recorrido />
            <Conceptos id={"galeria"} title={["ESPACIOS","INIGUALABLES"]} description={"3 exclusivos modelos con un ESTILO inigualable y excelentes acabados, cada una de 2 plantas, con amplios espacios excelente distribución."} type={"slider"} arraySlider={slider} buttonLink={"/disponibilidad"} buttonText={"VER DISPONIBILIDAD"}/>
            <Prototypes/>
            <Amenidades />
            <Conceptos title={["UBICACIÓN","PRIVILEGIADA"]} description={"Ubicado en la zona de Anitas, Punta Vela Residencial ha sido desarrollado para brindar a sus habitantes un ambiente exclusivo y seguro."} type={"image"} urlItem={"/assets/Home/img_ciudad-jurez_punta-vela.jpg"} style={"background"} buttonLink={"#ubicacion"} buttonText={"VER UBICACIÓN"}/> 
        </div>
        </>
    );
}
export default Home1